<template>
    <div class="answers-variant-input">
        <w-fx-table
            v-model="correct"
            :headers="headers"
            :items="items"
            hide-divider
            hide-default-header
            draggable
            selectable
            :single-select="singleSelect"
            item-key="id"
            draggable-key="weight"
        >
            <template #item.value="{ item, index }">
                <v-text-field
                    v-model="item.value"
                    dense
                    :placeholder="$trans('questions.hints.answer_variant') + ' ' + (index + 1)"
                    :rules="rules"
                    @focus="(e) => e.target.select()"
                    class="answer-variant-input-field"
                />
            </template>

            <template #item.actions="{ item }">
                <v-btn
                    icon
                    @click="onRemoveVariant(item)"
                >
                    <w-icon
                        value="CLOSE"
                    />
                </v-btn>
            </template>
        </w-fx-table>
        <div class="px-5">
            <slot></slot>
        </div>
        <div class="mt-2">
            <v-btn
                text
                color="primary"
                @click="onAddVariant"
            >
                <w-icon value="ADD" left/>
                <span>{{ $trans('questions.hints.add_more') }}</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { required } from '@/utils/validation'
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'
export default {
    name: 'AnswersVariantInput',
    mixins: [questionsTypeBasePropsMixin],
    props: {
        singleSelect: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: 'Question',
                    value: 'value',
                    class: 'pl-1',
                    itemClass: 'pl-1'
                },
                {
                    value: 'actions',
                    width: 46,
                    class: 'pa-0',
                    itemClass: 'pa-0'
                }
            ]
        },
        rules() {
            return [
                required
            ]
        },
        items() {
            const variants = this.variants

            return variants.sort((a, b) => (a.weight > b.weight) ? 1 : -1)
        }
    },
    watch: {
        correct() {
            this.onInput()
        }
    },
    data() {
        return {
            variants: [
                {
                    id: _.random(100, 999).toString(),
                    weight: 1,
                    value: this.$trans('questions.hints.answer_variant') + ' 1'
                },
                {
                    id: _.random(100, 999).toString(),
                    weight: 0,
                    value: this.$trans('questions.hints.answer_variant') + ' 2'
                }
            ],
            correct: []
        }
    },
    mounted() {
        this.$nextTick(() => {
            if(this.value.variants) {
                this.variants = [].concat(this.value.variants)
            }

            if(this.value.correct) {
                this.correct = [].concat(this.value.correct)
            }
        })
    },
    methods: {
        onAddVariant() {
            this.variants.push({
                id: _.random(100, 999).toString(),
                weight: this.variants.length
            })
        },
        onRemoveVariant(item) {
            this.variants = this.variants
                .filter(o => o.id !== item.id)
                .map((o, i) => {
                    o.weight = i

                    return o
                })
        },
        onDragged(variants) {
            this.variants = variants
        },
        onInput() {
            const options = {
                variants: this.variants,
                correct: this.correct.length > 0
                    ? this.correct
                    : null
            }
            this.$emit('input', options)
        }
    }
}
</script>

<style lang="scss">
.answers-variant-input {
    .answer-variant-input-field {
        margin-top: 0 !important;
        position: absolute;
        width: 100%;
        top: 8px;
        left: 0;

        .v-text-field__details {
            margin-bottom: 0 !important;
        }
    }
}
</style>
